/**
 * Import all polyfills.
 */
import 'url-search-params-polyfill';
import 'custom-event-polyfill';

/**
 * Import all required libraries.
 */
import Vue from 'vue';
import Vuex from 'vuex';
import * as VueGoogleMaps from 'gmap-vue';
import ServiceContainer from '@flashpointbv/solar-service-container';
import VueI18n from 'vue-i18n';
import {UICoreSticky, UICoreOffCanvas, UICoreBlazeSlider, UICoreFormValidator} from '@flashpointbv/solar-ui-core';
import 'lazysizes';

import 'img-comparison-slider';

new UICoreSticky().sticky();
new UICoreOffCanvas().offCanvas(true);
new UICoreBlazeSlider();
new UICoreFormValidator();

Vue.use(VueI18n);
Vue.use(Vuex);

Vue.use(VueGoogleMaps, {
    load: {
        key: window.Config['google_api_key'],
        libraries: 'places',
        region: window.Locale.substring(0, 2),
        language: window.Locale.substring(0, 2).toLowerCase()
    }
});

/*
 * Set important environment variables
 */
Environment().set('locale', window.Locale);
Environment().set('locale_short', window.Locale.substr(0, 2).toLowerCase());
for (let key in window.Config) Environment().set(key, window.Config[key]);

/*
* Create store instance and register the store modules
 */
import {CatalogCategory, CatalogProduct, CatalogBundleProduct, CatalogGroupedProduct, CatalogConfigurableProduct} from '@flashpointbv/solar-ui-catalog';
import {CheckoutAddress, CheckoutGlobal, CheckoutPayment, CheckoutQuote, CheckoutShipping, CheckoutTotals} from './stores/checkout';

import PreorderIndex from './stores/customer/pre-orders';
import Report from './stores/customer/report';
import InvoiceCopy from './stores/customer/invoice-copy';
import CatalogProductsByBikeCategory from './stores/products-by-bike';
import ProductQuickBuy from './stores/product/quick-buy';
import GlobalMessages from './stores/messages';
import QuickBuy from './stores/customer/quick-buy';
import GlobalCustomer from './stores/customer';

const store = new Vuex.Store({
    strict: false,
});

store.registerModule('GlobalMessages', GlobalMessages);

store.registerModule('CatalogCategory', CatalogCategory);

//todo @frontend @Joey
store.registerModule('CatalogProductsByBikeCategory', CatalogProductsByBikeCategory);

store.registerModule('CatalogProduct', CatalogProduct);
store.registerModule('CatalogBundleProduct', CatalogBundleProduct);
store.registerModule('CatalogGroupedProduct', CatalogGroupedProduct);
store.registerModule('CatalogConfigurableProduct', CatalogConfigurableProduct);

store.registerModule('ProductQuickBuy', ProductQuickBuy);

store.registerModule('CheckoutQuote', CheckoutQuote);
store.registerModule('CheckoutGlobal', CheckoutGlobal);
store.registerModule('CheckoutTotals', CheckoutTotals);
store.registerModule('CheckoutPayment', CheckoutPayment);
store.registerModule('CheckoutAddress', CheckoutAddress);
store.registerModule('CheckoutShipping', CheckoutShipping);

store.registerModule('GlobalCustomer', GlobalCustomer);
store.registerModule('PreorderIndex', PreorderIndex);
store.registerModule('QuickBuy', QuickBuy);
store.registerModule('Report', Report);
store.registerModule('InvoiceCopy', InvoiceCopy);
store.registerModule('ServiceForm', ServiceForm);

/**
 * Require application scripts.
 */
require('./prototypes');
require('./core');

/**
 * Register Vue components.
 */

ServiceContainer().addComponents([
    {key: 'global-messages', concrete: () => import('./components/GlobalMessages.vue')},
    {key: 'off-canvas', concrete: () => import('./components/OffCanvas.vue')},
    {key: 'global-modal', concrete: () => import('./components/GlobalModal.vue')},
    {key: 'autocomplete-form', concrete: require('../../default/js/components/search/AutocompleteForm.vue').default},
    {key: 'newsletter-block', concrete: () => import('./components/NewsletterBlock.vue')},
    {key: 'page-builder-store-locator', concrete: () => import('./components/page-builder/StoreLocator.vue')},
    {key: 'page-builder-hotspot', concrete: () => import('./components/page-builder/Hotspot.vue')},
    {key: 'store-locator', concrete: () => import('./components/physical-store/Locator.vue')},
    {key: 'fullscreen-store-locator', concrete: () => import('./components/page-builder/FullscreenStoreLocator.vue')},
    {key: 'checkout-checkout', concrete: () => import('./components/checkout/Checkout.vue')},
    {key: 'customer-switcher', concrete: () => import('./components/customer/customer-switcher/CustomerSwitcher.vue')},
    {key: 'catalog-product-configuration', concrete: () => import('../../default/js/components/catalog/product/ProductConfiguration.vue')},
    {key: 'catalog-product-attributes', concrete: () => import('../../default/js/components/catalog/product/Attributes.vue')},
    {key: 'catalog-product-price', concrete: () => import('../../default/js/components/catalog/product/ProductPrice.vue')},
    {key: 'catalog-product-simple', concrete: () => import('../../default/js/components/catalog/product/simple/ProductTypeSimple.vue')},
    {key: 'catalog-product-configurable', concrete: () => import('../../default/js/components/catalog/product/configurable/ProductTypeConfigurable.vue')},
    {key: 'catalog-product-configurable-select', concrete: () => import('../../default/js/components/catalog/product/configurable/type/OptionSelect.vue')},
    {key: 'catalog-product-configurable-swatch', concrete: () => import('../../default/js/components/catalog/product/configurable/type/OptionSwatch.vue')},
    {key: 'catalog-product-media', concrete: () => import('../../default/js/components/catalog/product/ProductMedia.vue')},
    {key: 'catalog-product-media-fullscreen', concrete: () => import('../../default/js/components/catalog/product/ProductMediaFullscreen.vue')},
    {key: 'catalog-product-quick-buy', concrete: () => import('../../default/js/components/catalog/product/quick-buy/QuickBuy.vue')},
    {key: 'catalog-product-slider', concrete: () => import('../../default/js/components/catalog/ProductSlider.vue')},
    {key: 'catalog-products-by-bike', concrete: () => import('./components/products-by-bike/ProductsByBike.vue')},
    {key: 'header-cart', concrete: () => import('./components/HeaderCart.vue')},
    {key: 'catalog-product-list-size-select', concrete: () => import('./components/catalog/toolbar/ProductListToolbarSizeSelect.vue')},
    {key: 'catalog-category-view', concrete: () => import('./views/catalog/CatalogCategoryView.vue')},
    {key: 'catalog-search-view', concrete: () => import('./views/catalog/CatalogSearchView.vue')},
    {key: 'catalog-filter-view', concrete: () => import('./views/catalog/CatalogFilterView.vue')},
    {key: 'catalog-product-list', concrete: () => import('./components/catalog/ProductList.vue')},
    {key: 'catalog-product-list-item-swatches', concrete: () => import('./components/catalog/ProductListItemSwatches.vue')},
    {key: 'catalog-product-list-item', concrete: () => import('./components/catalog/ProductListItem.vue')},
    {key: 'catalog-product-list-toolbar', concrete: () => import('./components/catalog/toolbar/ProductListToolbar.vue')},
    {key: 'catalog-product-list-toolbar-paginator', concrete: () => import('./components/catalog/toolbar/ProductListToolbarPaginator.vue')},
    {key: 'catalog-product-list-toolbar-sort-select', concrete: () => import('./components/catalog/toolbar/ProductListToolbarSortSelect.vue')},
    {key: 'catalog-product-list-toolbar-size-select', concrete: () => import('./components/catalog/toolbar/ProductListToolbarSizeSelect.vue')},
    {key: 'catalog-product-list-active-filters', concrete: () => import('./components/catalog/filter/ActiveFilters.vue')},
    {key: 'catalog-product-filter-list', concrete: () => import('./components/catalog/ProductFilterList.vue')},
    {key: 'catalog-product-list-attribute-filter', concrete: () => import('./components/catalog/filter/AttributeFilter.vue')},
    {key: 'catalog-product-list-swatch-filter', concrete: () => import('./components/catalog/filter/SwatchFilter.vue')},
    {key: 'catalog-product-list-price-filter', concrete: () => import('./components/catalog/filter/PriceFilter.vue')},

    // Customer portals
    {key: 'customer-downloads', concrete: () => import('./components/customer/downloads/Downloads.vue')},
    {key: 'customer-pre-orders-overview', concrete: () => import('./components/customer/pre-orders/OrderOverview.vue')},
    {key: 'customer-pre-orders-preorder', concrete: () => import('./components/customer/pre-orders/OrderPreorder.vue')},
    {key: 'customer-pre-orders-category', concrete: () => import('./components/customer/pre-orders/OrderPreorderCategory.vue')},
    {key: 'customer-pre-orders-category-action', concrete: () => import('./components/customer/pre-orders/OrderPreorderCategoryAction.vue')},
    {key: 'customer-pre-orders-category-color', concrete: () => import('./components/customer/pre-orders/OrderPreorderCategoryColor.vue')},
    {key: 'customer-pre-orders-bulk', concrete: () => import('./components/customer/pre-orders/OrderPreorderBulk.vue')},
    {key: 'quick-buy-customer-cart', concrete: () => import('./components/customer/quick-buy/QuickBuyCustomerCart.vue')},
    {key: 'quick-buy-product-import', concrete: () => import('./components/customer/quick-buy/QuickBuyProductsImport.vue')},
    {key: 'quick-buy-search-results', concrete: () => import('./components/customer/quick-buy/QuickBuySearchResults.vue')},
    {key: 'report-choice', concrete: () => import('./components/customer/report/ReportChoice.vue'),},
    {key: 'report-dashboard', concrete: () => import('./components/customer/report/ReportDashboard.vue'),},
    {key: 'invoice-date-selection', concrete: () => import('./components/customer/copy-invoice/InvoiceDateSelection.vue')},
    {key: 'invoice-type-selection', concrete: () => import('./components/customer/copy-invoice/InvoiceTypeSelection.vue')},
    {key: 'invoice-result-list', concrete: () => import('./components/customer/copy-invoice/InvoiceResultList.vue')},
    {key: 'service-dashboard', concrete: () => import('./components/customer/service/ServiceDashboard.vue')},

    // Cart
    {key: 'checkout-cart-order-comment', concrete: () => import('./components/checkout/cart/OrderComment.vue')},
    {key: 'checkout-cart-quote-item-comment', concrete: () => import('./components/checkout/cart/QuoteItemComment.vue')},
]);

import {localize} from 'vee-validate';
import vee_nl_NL from 'vee-validate/dist/locale/nl.json';
import vee_en_GB from 'vee-validate/dist/locale/en.json';
import vee_de_DE from 'vee-validate/dist/locale/de.json';
import vee_fr_FR from 'vee-validate/dist/locale/fr.json';
import vee_it_IT from 'vee-validate/dist/locale/it.json';
import vee_pt_PT from 'vee-validate/dist/locale/pt_PT.json';
import vee_es_ES from 'vee-validate/dist/locale/es.json';
import vee_pl_PL from 'vee-validate/dist/locale/pl.json';
import vee_ko_KO from 'vee-validate/dist/locale/ko.json';

localize({vee_en_GB, vee_nl_NL, vee_de_DE, vee_fr_FR, vee_it_IT, vee_pt_PT, vee_es_ES, vee_pl_PL, vee_ko_KO});
localize(`vee_${window.Locale === 'nl_BE' ? 'nl_NL' : window.Locale}`);

/**
 * Swiper
 */
import Swiper from 'swiper';
import {Pagination, Navigation, EffectCoverflow} from 'swiper/modules';
import 'swiper/css/bundle';
import ServiceForm from "./stores/customer/service";

/**
 * Intersection Observer
 */
import {intersectionObserverHelper} from './shared/helpers/intersection-observer';
import Environment from '@flashpointbv/solar-ui-environment';

window.intersectionObserverHelper = intersectionObserverHelper;


Swiper.use([
    Pagination,
    Navigation,
    EffectCoverflow
]);

window.Swiper = Swiper;
window.Navigation = Navigation;
window.Pagination = Pagination;



let messages = {};
messages[window.Locale] = window.Translations;

const i18n = new VueI18n({
    locale: window.Locale,
    fallbackLocale: 'nl_NL',
    formatFallbackMessages: true,
    silentTranslationWarn: true,
    messages
});

new Vue({
    store,
    i18n,
    components: ServiceContainer().getComponents(),
    data: {
        csrfToken: window.csrfToken
    },
    async created() {
        if (Environment().get('can_use_quote_switcher')) {
            this.$store.commit('GlobalCustomer/SET_SELECTED_CUSTOMER', {});
        }

        this.$eventBus.$on('item-rendered', () => {
            const catalogCategoryProductPlaceholders = document.querySelector('.category__placeholder');
            if (catalogCategoryProductPlaceholders) {
                (catalogCategoryProductPlaceholders as HTMLElement).style.display = 'none';
            }
        });
    }
}).$mount('#solar-app');
